/* eslint-disable prettier/prettier */
// src/pages/Post.js

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { PageLayout } from "../components/page-layout";

const Post = () => {
  const [alertMessage, setAlertMessage] = useState("");
  const location = useLocation();

  useEffect(() => {
    const checkoutId = new URLSearchParams(location.search).get("c_id");

    if (!checkoutId) {
      window.location.href = "https://repbot.ai";
      return;
    }

    localStorage.setItem("cid", checkoutId);
  }, [location]);

  const copySignupLink = () => {
    const currentUrl = window.location.href;
    navigator.clipboard.writeText(currentUrl);
    setAlertMessage("Signup link copied to clipboard! You can now share it.");
  };

  const containerStyle = {
    maxWidth: "800px",
    margin: "0 auto",
    padding: "20vh 20px",
    textAlign: "center",
    fontFamily: "Arial, sans-serif",
  };

  const headingStyle = {
    color: "#0A2540",
    fontSize: "4.5rem",
    marginBottom: "20px",
  };

  const subheadingStyle = {
    color: "#2f8b1e",
    fontSize: "3rem",
    marginBottom: "40px",
  };

  const paragraphStyle = {
    color: "#4F5D75",
    fontSize: "1.75rem",
    lineHeight: "1.6",
    marginBottom: "20px",
  };

  const boxStyle = {
    border: "1px solid #E0E0E0",
    borderRadius: "8px",
    padding: "20px",
    backgroundColor: "#F9F9F9",
    marginTop: "30px",
  };

  const buttonStyle = {
    backgroundColor: "#707e83",
    color: "white",
    border: "none",
    padding: "12px 24px",
    fontSize: "2rem",
    borderRadius: "4px",
    cursor: "pointer",
    transition: "background-color 0.3s",
  };

  return (
    <PageLayout>
      <div style={containerStyle}>
        <h1 style={headingStyle}>Thank You for Subscribing! Your payment was successful.</h1>
        <h2 style={subheadingStyle}>You can sign up for an account by clicking the &quot;Sign Up&quot; button at the top right corner of the page.</h2>
        

        <div style={boxStyle}>
          <p style={paragraphStyle}>
            If you&apos;re not signing up now, click the button below to copy your personalized sign-up link. You can
            email it to yourself or share it with a team member. If this link isn&apos;t used to sign up, you may need to
            contact support to connect the subscription to your account.
          </p>
          <button
            onClick={copySignupLink}
            style={buttonStyle}
          >
            Copy Signup Link
          </button>

          {alertMessage && <p style={{...paragraphStyle, marginTop: "10px", color: "#00A86B", fontWeight: "bold"}}>{alertMessage}</p>}
        </div>
      </div>
    </PageLayout>
  );
};

export default Post;