/* eslint-disable prettier/prettier */
// src/pages/dashboard-page.js

import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { PageLayout } from "../components/page-layout";
import useDocumentTitle from "../hooks/useDocumentTitle";
import Retool from "react-retool";
import Modal from "../components/modal";
import GoogleSSO from "../components/GoogleSSO";
import useIframeModal from "../hooks/useIframeModal";
import useAccountInfo from "../hooks/useAccountInfo"; // Import the new hook

const DashboardPage = () => {
  useDocumentTitle("Dashboard");

  const { user } = useAuth0();
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Initialize the iframe modal hook with the expected origin and callback
  useIframeModal("https://tryrepbot.retool.com", () => setIsModalOpen(true));

  // Use the useAccountInfo hook to listen for accountId and accountType updates from Retool iframe
  useAccountInfo("https://tryrepbot.retool.com");

  // Data to pass to Retool, only sending userEmail
  const retoolData = {
    userEmail: user.email,
  };

  const closeModal = () => {
    setIsModalOpen(false); // Close modal
  };

  return (
    <PageLayout>
      {isModalOpen && (
        <Modal onClose={closeModal}>
          <GoogleSSO />
        </Modal>
      )}

      <Retool
        url="https://tryrepbot.retool.com/p/dashboard" 
        width="100%"
        height="800px"
        data={retoolData}
      />
    </PageLayout>
  );
};

export default DashboardPage;
