/* eslint-disable prettier/prettier */
// src/hooks/useAccountInfo.js
import { useEffect } from "react";

/**
 * Custom hook to listen for accountId and accountType messages from an iframe and update localStorage.
 *
 * @param {string} expectedOrigin - The origin to validate incoming messages.
 */
const useAccountInfo = (expectedOrigin) => {
  useEffect(() => {
    /**
     * Handler for message events.
     *
     * @param {MessageEvent} event - The message event.
     */
    const handleMessage = (event) => {
      // Validate the message origin
      if (event.origin !== expectedOrigin) {
        console.warn(`Ignored message from unexpected origin: ${event.origin}`);
        return;
      }

      // Destructure accountId and accountType from the message data
      const { accountId, accountType } = event.data || {};

      if (accountId && accountType) {
        // Check and update accountId in localStorage if needed
        const storedAccountId = localStorage.getItem("accountId");
        if (!storedAccountId || storedAccountId !== accountId) {
          console.log(`Updating accountId in localStorage: ${accountId}`);
          localStorage.setItem("accountId", accountId);
        }

        // Check and update accountType in localStorage if needed
        const storedAccountType = localStorage.getItem("accountType");
        if (!storedAccountType || storedAccountType !== accountType) {
          console.log(`Updating accountType in localStorage: ${accountType}`);
          localStorage.setItem("accountType", accountType);
        }
      } else {
        console.warn("Received message without accountId or accountType");
      }
    };

    // Add the event listener
    window.addEventListener("message", handleMessage);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [expectedOrigin]);
};

export default useAccountInfo;
